<template>
  <b-modal
    id="modal-programarCita"
    size="lg"
    title="Reservar Cita"
    hide-footer
    @hidden="onHidden"
  >
    <div class="row">
      <div class="col-sm-5 pr-0">
        <div class="form-group">
          <label>Paciente:</label>
          <multiselect
            v-model="pacienteSelected"
            :options="getPacientes"
            :loading="isLoadingComboPacientes"
            @open="obtenerListaPacientes('')"
            placeholder=""
            track-by="id"
            label="full_name"
            select-label=""
            deselect-label="X"
          >
            <span slot="caret" v-if="!(pacienteSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.full_name}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.full_name}}</small>
            </template>
          </multiselect>
        </div>
      </div>
      <!--  <div class="col-sm-1" v-if="pacienteSelected !== null">
        <div class="form-group">
          <label style="user-select: none"><br></label>
          <button
            class="btn btn-success pr-3"
            @click="openModalPaciente"
          >
            +
          </button>
          <ModalPacienteNuevo @nuevoPaciente="pacienteSelected = $event"/>
        </div>
      </div> -->
      <div class="col-sm-7 text-right" v-if="pacienteSelected">
        <div class="form-group">
          <div class="row">
            <label style="user-select: none"><br></label>
          </div>
          <button
      :class="['btn', isClinic ? 'btnOrange' : 'btn-secondary', 'mr-2']"
      @click="selectType()"
    >
      Clinica
    </button>
    <button
      :class="['btn', !isClinic ? 'btnOrange' : 'btn-secondary', 'mr-2']"
      @click="selectType()"
    >
      Medico
    </button>
      </div>
      </div>
    </div>
    <div class="row mx-0 mb-2" v-if="pacienteSelected">
      <div :class="{'bg-skyblue': !isClinic, 'bg-row': isClinic}" class="col-sm-12 py-2">
        <button
        :class="{'btn btn-success': buttonConsultaActive,
          'btn btn-secondary': !buttonConsultaActive}"
        @click="isActiveButton('consulta')"
        class="btn px-4 mr-3">
        Consulta
      </button>
      <button
        :class="{'btn btn-success': buttonReConsultaActive,
          'btn btn-secondary': !buttonReConsultaActive}"
        @click="isActiveButton('reconsulta')"
        style="display: none;"
        disabled
        class="btn px-4 mr-3">
        Re Consulta
      </button>
      <button
        :class="{'btn btn-success': buttonServicioActive,
          'btn btn-secondary': !buttonServicioActive}"
        @click="isActiveButton('servicio')"
        class="btn px-4 mr-3">
        Servicios
      </button>
      <button
        :class="{'btn btn-success': buttonTratamientoActive,
          'btn btn-secondary': !buttonTratamientoActive}"
        @click="isActiveButton('tratamiento')"
        style="display: none"
        disabled
        class="btn px-4">
        Tratamiento
      </button>
      </div>
    </div>
    <div class="row mb-2" v-if="(buttonServicioActive || buttonConsultaActive) && pacienteSelected">
      <div class="col-sm-7 pr-1">
        <div class="form-group">
          <div class="row">
            <div class="col-sm-6">
              <label>Servicios:</label>
            </div>
          </div>
          <multiselect ref="comboServices"
              v-model="serviciosSelected"
              :options="listaServiciosInteligente"
              :loading="loaderComboServicio"
              @select="selectServices"
              placeholder=""
              label="nombre" track-by="id"
              select-label=""
              deselect-label="X">
                <span slot="caret" v-if="!(serviciosSelected)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
                <template slot="singleLabel" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
                <template slot="option" slot-scope="props">
                  <small>{{props.option.nombre}}</small>
                </template>
            </multiselect>
        </div>
      </div>
      <div class="col-sm-4 px-1">
        <div class="row">
          <div class="col-sm-3 pr-1 mt-4">
            <button
              :class="['btn', isPaid
                ? 'btn-success' : 'btn-secondary', 'btn-block', 'mt-2 py-1', 'px-1']"
              @click="togglePaymentStatus"
            >
              <small>Paga</small>
            </button>
          </div>
          <div class="col-sm-3 pl-0 mt-4">
            <button
              :class="['btn', !isPaid ?
                'btn-success' : 'btn-secondary', 'btn-block', 'mt-2 py-1', 'px-1']"
              @click="togglePaymentStatus"
            >
              <small>No Paga</small>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 text-right" v-if="medicoSelected">
          <button v-if="stateTurno1"
            @click="selectTurno1"
            class="btn mr-3"
            :class="turno === 1? 'btn-success' : 'btn-secondary'"
          >Turno 1</button>
          <button v-if="stateTurno2"
            @click="selectTurno2"
            class="btn"
            :class="turno === 2? 'btn-success' : 'btn-secondary'"
          >Turno 2</button>
        </div>
    </div>
    <div class="row mb-2" v-if="this.serviciosSelected ||
      (!this.isClinic && this.pacienteSelected
        && (this.buttonConsultaActive || this.buttonServicioActive))">
      <div class="col-sm-5 pr-0">
        <div class="form-group">
          <div class="row">
            <div class="col-sm-4">
              <label>Profesional:</label>
            </div>
            <div class="col text-right">
              <label :class="textTitleCard(tipoTurnoMedicoId)">({{ titleCard }})</label>
            </div>
          </div>
          <multiselect v-model="medicoSelected"
          :options="getListaMedicos()"
          :loading="loadingMedico"
          placeholder=""
          label="nombre"
          track-by="id"
          select-label=""
          deselect-label="X">
          <span slot="caret" v-if="medicoSelected !== null"></span>
          <span slot="noResult">Sin Resultados</span>
          <span slot="noOptions">Lista Vacia</span>
          <template slot="singleLabel" slot-scope="props">
          <small>{{ props.option.nombre }}</small>
          </template>
          <template slot="option" slot-scope="props">
          <small>{{ props.option.nombre }}</small>
          </template>
          </multiselect>
        </div>
      </div>
      <template v-if="medicoSelected">
        <div class="col-sm-2 px-2" >
          <div class="form-group">
            <label>Fecha:</label>
            <date-picker
              id="inputFecha"
              class="w-100"
              v-model="inputFecha"
              @input="onInputFecha"
              :disabled-date="disabledBeforeToday"
              lang="es"
              format="DD/MM/YYYY"
              type="date"
              value-type="date"
            ></date-picker>
          </div>
        </div>
        <div class="col-sm-2 mt-1 pr-0 pl-1"  v-if="tipoTurnoMedicoId === 1">
          <div class="form-group">
            <span class="d-inline-block"></span>
            <input
            :value="horariosDisponibles"
            @click="isSeleccionManual=!isSeleccionManual"
            type="text"
            class="form-control text-center"
            style="cursor:pointer;"
            readonly="true"
          >
          </div>
        </div>
        <div class="col-sm-3 mt-4 text-right" v-if="tipoTurnoMedicoId !== 3">
        <div
          v-if="isSeleccionManual || tipoTurnoMedicoId === 2"
        >
          <input
            v-model="horarioManualValue"
            @input="sendData"
            type="time"
            class="form-control"
          >
        </div>
          <div
            v-if="!isSeleccionManual && tipoTurnoMedicoId === 1"
          >
            <multiselect
              v-model="horarioSelected"
              :options="listaHorarios"
              @input="sendData"
              placeholder=""
              selectedLabel=""
              label="hora"
              track-by="ticket"
              select-label=""
              deselect-label="X"
            >
              <span slot="caret" v-if="!(horarioSelected===null)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">No trabaja</span>
              <template slot="singleLabel" slot-scope="props">
                <small>{{props.option.ticket}} - {{props.option.hora}}</small>
              </template>
              <template slot="option" slot-scope="props">
                <small
                  v-if="formatOptionLabel(props.option.hora)"
                  class="yellow-border"> {{ props.option.ticket }} - {{ props.option.hora }}</small>
                <small v-else>{{ props.option.ticket }} - {{ props.option.hora }}</small>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="col-sm-3 mt-4 pr-1" v-if="tipoTurnoMedicoId === 3">
          <button
            :class="{ 'btn-success': medicinaGeneralSelected,
              'btn-secondary': !medicinaGeneralSelected }"
            class="btn btn-block"
            @click="toggleMedicinaGeneral">Medicina General</button>
        </div>
        <div class="col-sm-2 mt-4 pl-1" v-if="tipoTurnoMedicoId === 3">
          <button
            :class="{ 'btn-success': emergenciaSelected, 'btn-secondary': !emergenciaSelected }"
            class="btn btn-block"
            @click="toggleEmergencia">Emergencia</button>
        </div>
      </template>
    </div>
    <div class="row mt-3" v-if="pacienteSelected">
      <div class="col-sm-12 text-right">
        <button
          class="btn btn-secondary mr-2"
          @click="hideModal"
        >
          Cancelar
        </button>
        <button
          class="btn btnOrange mr-2"
          @click="guardarModal"
        >
          Guardar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'ModalProgramarCita',
  components: {
    Multiselect,
    DatePicker,
  },
  props: {
    getMedicos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      listaPacientes: [{ id: 0, full_name: 'prueba' }],
      fecha: null,
      listaMedicos: [{ id: 0, nombre: 'prueba' }],
      servicioSelected: null,
      stateTurno1: false,
      stateTurno2: false,
      btnDisabled: true,
      buttonClinicaActive: null,
      buttonMedicoActive: null,
      buttonConsultaActive: false,
      buttonReConsultaActive: false,
      buttonServicioActive: false,
      buttonTratamientoActive: false,
      loaderComboServicio: false,
      loadingMedico: false,
      inputFecha: null,
      horarioSelected: null,
      listaHorarios: [],
      turno: 1,
      turnoId: null,
      isDisabledEnfermeria: false,
      titleCard: 'Programar Atención',
      tipoTurnoMedicoId: 0,
      isSeleccionManual: false,
      horarioManualValue: '',
      hasMedicTurnos: false,
      medicinaGeneralSelected: false,
      emergenciaSelected: false,
      tipo_atencion_id: null,
      isPaid: false,
      isClinic: true,
      dataGuardar: {},
      serviciosSelected: null,
      medicoSelected: null,
      pacienteSelected: null,
    };
  },
  async created() {
    this.getServiciosApi();
  },
  methods: {
    hideModal() {
      this.resetModalData();
      this.$bvModal.hide('modal-programarCita');
    },
    openModalPaciente() {
      this.$bvModal.show('modal-pacienteNuevo');
    },
    togglePaymentStatus() {
      this.isPaid = !this.isPaid;
      this.sendData();
    },
    selectType() {
      this.isClinic = !this.isClinic;
      this.medicoSelected = null;
      this.serviciosSelected = null;
      this.inputFecha = null;
      this.horarioSelected = null;
      this.sendData();
    },
    isActiveButton(buttonType) {
      this.buttonConsultaActive = false;
      this.buttonReConsultaActive = false;
      this.buttonServicioActive = false;
      this.buttonTratamientoActive = false;

      if (buttonType === 'consulta') {
        this.buttonConsultaActive = !this.buttonConsultaActive;
      } else if (buttonType === 'reconsulta') {
        this.buttonReConsultaActive = !this.buttonReConsultaActive;
      } else if (buttonType === 'servicio') {
        this.buttonServicioActive = !this.buttonServicioActive;
      } else if (buttonType === 'tratamiento') {
        this.buttonTratamientoActive = !this.buttonTratamientoActive;
      }
    },
    getListaMedicos() {
      if (this.isClinic) {
        return this.getMedicosTratantes;
      }
      return this.getMedicos;
    },
    async getTurnoMedicoStatus() {
      try {
        const REQUEST = {
          medico_id: this.medicoSelected.id,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/turno/configuracion', {
          params: REQUEST,
        });
        const TURNOS_MEDICO = RESPONSE.data.data.turno_medico;
        if (TURNOS_MEDICO.length > 0) {
          const T1 = TURNOS_MEDICO.find((el) => el.numero_turno === 1) ?? null;
          const T2 = TURNOS_MEDICO.find((el) => el.numero_turno === 2) ?? null;
          if (T1 !== null) {
            this.stateTurno1 = true;
            this.btnDisabled = false;
          } else {
            this.stateTurno1 = false;
          }
          if (T2 !== null) {
            this.stateTurno2 = true;
            this.btnDisabled = false;
          } else {
            this.stateTurno2 = false;
          }
          if (T1 !== null && T2 === null) {
            this.turno = 1;
          }
          if (T1 === null && T2 !== null) {
            this.turno = 2;
          }
        } else {
          this.hasMedicTurnos = false;
          this.btnDisabled = true;
          this.stateTurno1 = false;
          this.stateTurno2 = false;
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    toggleMedicinaGeneral() {
      if (this.tipoTurnoMedicoId === 3) {
        this.medicinaGeneralSelected = true;
        this.emergenciaSelected = false;
        this.tipo_atencion_id = 1;
        this.sendData();
      }
    },
    toggleEmergencia() {
      if (this.tipoTurnoMedicoId === 3) {
        this.emergenciaSelected = true;
        this.medicinaGeneralSelected = false;
        this.tipo_atencion_id = 2;
        this.sendData();
      }
    },
    sendData() {
      let fechaTurno = null;
      let horaTurno = null;
      let numeroTicket = null;
      if (this.inputFecha !== null) {
        fechaTurno = moment(this.inputFecha).format('YYYY-MM-DD');
      }
      if (this.tipoTurnoMedicoId === 1) {
        if (this.horarioSelected === null || this.isSeleccionManual) {
          horaTurno = this.horarioManualValue;
        } else {
          horaTurno = this.horarioSelected?.hora ?? null;
          numeroTicket = this.horarioSelected?.ticket ?? null;
        }
      }
      if (this.tipoTurnoMedicoId === 2) {
        horaTurno = this.horarioManualValue;
      }
      const MEDICO_ID = this.medicoSelected
        ? this.medicoSelected.id : null;

      let medicoTerceroId = null;
      if (!this.isClinic) {
        medicoTerceroId = this.medicoSelected ? this.medicoSelected.id : null;
      }
      const DATA = {
        paciente_id: this.pacienteSelected ? this.pacienteSelected.id : null,
        tipo: this.buttonConsultaActive ? 'consulta' : 'servicio',
        tipo_servicios_id: this.serviciosSelected ? this.serviciosSelected.id : null,
        medico_id: MEDICO_ID,
        medico_tercero_id: medicoTerceroId,
        paga: this.isPaid,
        venta_detalle_turno_id: 0,
        numero_ticket: numeroTicket,
        turno_medico_id: this.turnoId,
        fecha_turno: fechaTurno,
        hora_turno: horaTurno === '' ? null : horaTurno,

        is_turno_manual: this.isSeleccionManual,
        medicina_general_selected: this.medicinaGeneralSelected,
        emergencia_selected: this.emergenciaSelected,
        tipo_atencion_id: this.tipo_atencion_id,
      };
      this.dataGuardar = DATA;
    },
    async guardarModal() {
      /* if (this.buttonConsultaActive === false || !this.buttonServicioActive === false) {
        util.showNotify('Debe Seleccionar una accion Consulta o Servicio', 'warn');
        return;
      } */
      if (!this.serviciosSelected) {
        util.showNotify('Debe Seleccionar un Servicio', 'warn');
        return;
      }
      if (this.medicoSelected === null) {
        util.showNotify('Debe Seleccionar un Profesional', 'warn');
        return;
      }
      try {
        this.$bvModal.hide('modal-programarCita');
        const RESPONSE = await axios.post('clinic/consultation/patient/reserva/store', this.dataGuardar);
        util.showNotify(RESPONSE.data.message, 'success');
        this.$emit('cita-agregada');
        this.resetModalData();
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    onHidden() {
      this.resetModalData();
    },
    resetModalData() {
      this.pacienteSelected = null;
      this.medicoSelected = null;
      this.serviciosSelected = null;
      this.inputFecha = null;
      this.horarioSelected = null;
      // Restablece otros datos del modal según sea necesario
    },
    textTitleCard(idTurno) {
      if (idTurno === 0) {
        return 'text-danger'; // Rojo
      } if (idTurno === 1) {
        return 'text-success'; // Verde
      } if (idTurno === 3) {
        return 'text-warning'; // Amarillo
      }
      return '';
    },
    formatTime(input) {
      if (input.includes(' - ')) {
        const parts = input.split(' - ');
        const timePart = parts[0];
        const formattedTime = moment(timePart, 'HH:mm:ss').format('HH:mm');
        if (parts.length > 1) {
          return `${formattedTime} - ${parts.slice(1).join(' - ')}`;
        }
        return formattedTime;
      }
      return moment(input, 'HH:mm:ss').format('HH:mm');
    },
    formatOptionLabel(option) {
      if (option.includes(' - ')) {
        const parts = option.split(' - ');
        const timePart = parts[0];
        if (moment(timePart, 'HH:mm:ss').format('HH:mm') === this.horarioSelected?.hora) {
          return true;
        }
      } else if (moment(option, 'HH:mm:ss').format('HH:mm') === this.horarioSelected?.hora) {
        return true;
      }
      return false;
    },
    cargarListaHorarios(lista) {
      const FECHA_SELECCIONADA = moment(this.inputFecha).format('YYYY-MM-DD');
      const FECHA_ACTUAL = moment(new Date()).format('YYYY-MM-DD');
      const HORA_ACTUAL = moment(new Date()).format('HH:mm');
      const TEMP_LIST = [];
      lista.forEach((el) => {
        let estado = false;
        let { hora } = el;
        if (el.hora === 'NO Disponible') {
          estado = true;
        } else if (FECHA_SELECCIONADA < FECHA_ACTUAL) {
          estado = true;
        } else if (HORA_ACTUAL > moment(el.hora, 'HH:mm:ss').format('HH:mm') && FECHA_SELECCIONADA === FECHA_ACTUAL) {
          estado = true;
        } else if (this.horarioSelected != null) {
          if (moment(el.hora, 'HH:mm:ss').format('HH:mm') === this.horarioSelected?.hora) {
            estado = true;
          }
        }
        if (this.numeroTicketFromEdit !== null && this.horaTurnoFromEdit !== null) {
          if (el.ticket === this.numeroTicketFromEdit) {
            hora = this.formatTime(this.horaTurnoFromEdit);
          }
        }
        if (hora) {
          hora = this.formatTime(hora);
        } else {
          hora = '';
        }
        if (hora.includes(' - ')) {
          estado = true;
        }
        const ITEM = {
          $isDisabled: estado,
          ticket: el.ticket,
          hora,
        };
        TEMP_LIST.push(ITEM);
      });
      this.listaHorarios = TEMP_LIST;
    },
    async getDataModal() {
      if (this.medicoSelected === null || this.inputFecha === null) {
        return;
      }
      try {
        const REQUEST = {
          medico_id: this.medicoSelected.id,
          fecha: moment(this.inputFecha).format('YYYY-MM-DD'),
          turno: this.turno,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/turno/', {
          params: REQUEST,
        });
        this.turnoId = RESPONSE.data.data.turno_medico?.id ?? null;
        const TURNOS = RESPONSE.data.data.turno_medico?.turnos ?? [];
        this.titleCard = RESPONSE.data.data.tipo_turno?.tipo_turno ?? 'Programar Atención';
        this.tipoTurnoMedicoId = RESPONSE.data.data.tipo_turno?.tipo_turno_medico_id ?? 0;
        this.cargarListaHorarios(TURNOS);
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
      }
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    async onInputFecha() {
      this.horarioSelected = null;
      this.isSeleccionManual = false;
      this.listaHorarios = [];
      await this.getDataModal();
      this.sendData();
    },
    async selectTurno1() {
      this.turno = 1;
      this.horarioSelected = null;
      await this.getDataModal();
      this.sendData();
    },
    async selectTurno2() {
      this.turno = 2;
      this.horarioSelected = null;
      await this.getDataModal();
      this.sendData();
    },
    setHorarioActual() {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      this.horarioManualValue = `${hours}:${minutes}`;
      this.sendData();
    },
    async selectServices(item) {
      this.medicoSelected = null;
      if (this.isClinic) {
        await this.getTratanteByServicioId(item.id);
      }
    },
    ...mapActions('formFactura', [
      'obtenerListaPacientes',
      'getTratanteByServicioId',
    ]),
    ...mapActions('addServiceModal', [
      'getServiciosApi',
    ]),
  },
  computed: {
    horariosDisponibles() {
      if (this.isSeleccionManual) {
        this.setHorarioActual();
        return 'Abierto';
      }
      const LISTA_DISPONIBLES = [];
      this.listaHorarios.forEach((el) => {
        if (el.$isDisabled === false) {
          LISTA_DISPONIBLES.push(el);
        }
      });
      return `Disponible: ${LISTA_DISPONIBLES.length}`;
    },
    listaServiciosInteligente() {
      return this.getServicios;
    },
    ...mapGetters('formFactura', [
      'getPacientes',
      'getMedicosTratantes', // (llegara en  otra api)
    ]),
    ...mapGetters('addServiceModal', [
      'getServicios',
    ]),
    ...mapState('formFactura', [
      'isLoadingComboPacientes',
    ]),
  },
  watch: {
    async medicoSelected(newVal) {
      this.inputFecha = new Date();
      this.horarioSelected = null;
      /* this.horarioManualValue = ''; */
      this.isSeleccionManual = false;
      this.listaHorarios = [];
      this.turno = 1;
      this.turnoId = null;
      this.sendData();
      if (newVal !== null) {
        await this.getTurnoMedicoStatus();
        this.getDataModal();
      } else {
        this.btnDisabled = this.horaTurnoFromEdit === null;
        this.hasMedicTurnos = false;
      }
    },
    medicinaGeneralSelected(newValue, oldValue) {
      if (newValue && oldValue !== newValue) {
        this.emergenciaSelected = false;
        this.sendData();
      }
    },
    emergenciaSelected(newValue, oldValue) {
      if (newValue && oldValue !== newValue) {
        this.medicinaGeneralSelected = false;
        this.sendData();
      }
    },
  },
};
</script>

<style scoped>
.bg-skyblue
{
  background: #61c6f2 !important;
}
.bg-row {
  background-color: #eee;
}
.btnOrange {
  color: white;
  background-color: #F4B375;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
